<template lang="pug">
  .register-container
    .pos-fixed
      top-bar
    .pt-top-height
      .content
        .title 
          h2 注册
          .go-login 已有账号？
            span.c-pointer(@click="toLogin") 去登录
              img.arrow-icon(src="@/assets/images/arrow.png")
        el-form.register-form(
          ref='registerForm' 
          :model='registerForm' 
          :rules="rules" 
          :show-message="isSubmit"
          label-position="right")
          el-form-item(label='昵称:' prop="nickname")
            el-input(v-model='registerForm.nickname')
          el-form-item(label='手机号:' prop="mobile")
            el-input(v-model='registerForm.mobile')
          el-form-item(label='验证码:' prop="code")
            el-input.code-input(v-model='registerForm.code')
            el-button.getcode-btn.primary-btn(:disabled="!canGetCode" @click='getCode') 获取验证码
          el-form-item(label='输入密码:' prop="password")
            el-input(v-model='registerForm.password' type="password")
          el-form-item.last-item(label='确认密码:' prop="password_confirmation")
            el-input(v-model='registerForm.password_confirmation' type="password")
          el-form-item.check-box(prop='consent_agreement')
            el-checkbox(v-model='registerForm.consent_agreement')
            span.c-pointer(@click="protocolDialog = true") 同意用户平台协议
          el-button.login-btn.primary-btn(:disabled="!canLogin" :loading="loginLoading" @click='registerSubmit') 立即注册
    bottom-foot
    el-dialog.protocol-container.login-container(
      title="知鸦分销平台用户协议"
      :visible.sync='protocolDialog'
      width='990px'
      center)
      .p_content(v-html="protocol")
</template>

<script>
import {register, getCode, getSubmitToken} from '@/api/login'
import {getInformation} from '@/api/index'
export default {
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"))
      } else if (this.checkTel(value)) {
        callback(new Error("手机号格式有误"))
      } else {
        callback()
      }
    }
    // 验证密码
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"))
      } else if (this.checkPasswordLength(value)) {
        callback(new Error("密码长度不能小于6位,由数字和字母组成"))
      } else {
        callback()
      }
    }
    // 确认密码
    const validateSamePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认密码"))
      } else if (this.checkPasswordLength(value)) {
        callback(new Error("密码长度不能小于6位,由数字和字母组成"))
      } else if (value != this.registerForm.password) {
        callback(new Error("新旧密码须完全一致"))
      } else {
        callback()
      }
    }
    const validateCheck = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请同意用户平台协议"))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"))
      } else if (this.checkNumber(value)) {
        callback(new Error("验证码为6位数字"))
      } else {
        callback()
      }
    }
    return {
      registerForm:{
        nickname:'',
        mobile:'',
        code:'',
        password:'',
        password_confirmation:'',
        consent_agreement:false
      },
      rules: {
        nickname: [
          { required: true, message: "请输入昵称" , trigger: "blur"  },
          { min:1, max: 10, message: '长度在 1 到 10 个字符之间', trigger: 'blur' }
        ],
        mobile: [{validator: validateUsername, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur"  }],
        password: [{ validator: validatePassword, trigger: "blur"  }],
        password_confirmation: [{ validator: validateSamePassword , trigger: "blur"  }],
        consent_agreement: [{ validator: validateCheck , trigger: "change"  }]
      },
      canGetCode:true,
      canLogin:true,
      loginLoading:false,
      isSubmit:false,
      codeTimer:null,
      protocolDialog:false,
      protocol:''
    }
  },
  methods:{
    registerSubmit(){
      this.$refs["registerForm"].validate(valid => {
        if (valid) {
          getSubmitToken().then(res => {
            if (res.code == 0) {
              let submit_token = res.data.submit_token
              register({
                nickname:this.registerForm.nickname,
                mobile:this.registerForm.mobile,
                code:this.registerForm.code,
                password:this.registerForm.password,
                password_confirmation:this.registerForm.password_confirmation,
                consent_agreement:this.registerForm.consent_agreement ? 1:0,
                submit_token:submit_token
              }).then(res => {
                if (res.code == 0) {
                  // 注册成功
                  this.canLogin = false
                  this.loginLoading = true
                  this.successMes(res.msg)
                  localStorage.isJustRegistered = true
                  setTimeout(()=>{
                    this.$router.push({path:'/'})
                  },500)
                }
              })
            }
          })
        } else {
          this.isSubmit = true
          this.errorMes('校验失败')
        }
      })
    },
    getCode(){
      if(this.registerForm.mobile != ''){
        if(!this.checkTel(this.registerForm.mobile)){
          getCode({
            mobile:this.registerForm.mobile,
            type:1
          }).then(res => {
            if (res.code == 0) {
              this.successMes(res.msg)
              this.startTimer()
            }
          })
        } else {
          this.errorMes('手机号格式有误')
        }
      } else {
        this.errorMes('请输入手机号')
      }
    },
    startTimer(){
      this.canGetCode = false
      // 定时器
      var s = 60
      if (this.codeTimer) {
        clearInterval(this.codeTimer)
      }
      this.codeTimer = setInterval(() => {
        s--
        if (s == 0) {
          clearInterval(this.codeTimer)
          this.canGetCode = true
        }
      }, 1000)
    },
    getInformation(){
      getInformation('5',{
        params:{
          flag:'row'
        }
      }).then(res => {
        if(res.code == 0) {
          this.protocol = res.data.content
        }
      })
    },
    toLogin(){
      this.$store.commit('CONTROL_LOGIN_DIALOG',true)
    }
  },
  mounted(){
    this.$store.commit('CONTROL_LOGIN_DIALOG',false)
    this.getInformation()
  }
}
</script>

<style lang="less">
.register-container{
  .pt-top-height{
    padding-bottom: 100px;
    background: url('~@/assets/images/login/register-bg.png') no-repeat;
    background-size: cover;
  }
  .content{
    box-sizing: border-box;
    width: 460px;
    height: 674px;
    box-shadow:0px 2px 10px 0px rgba(42,103,255,0.3);
    border-radius:10px;
    margin:100px auto 0;
    padding: 30px 40px;
    .title{
      position: relative;
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      h2{
        display: inline-block;
        color: #333;
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
      }
      .go-login{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 14px;
        color: #2A67FF;
        .arrow-icon{
          width: 18px;
          height: 18px;
          vertical-align: middle;
        }
      }
    }
    .el-form-item{
      margin-bottom: 30px;
      .el-form-item__label{
        width: 100%;
        text-align: left;
        line-height: 14px;
        font-size: 14px;
        color: #666;
        margin-bottom: 10px;
      }
      .el-form-item__content{
        line-height: 40px;
        font-size: 16px;
        color: #333;
        width: 100%;
        .el-input{
          width: 380px;
          .el-input__inner{
            height: 40px;
            line-height: 40px;
            border-color: #d7dae2;
          }
        }
        .code-input{
          width: 272px;
        }
      }
    }
    .el-form-item.last-item{
      margin-bottom: 20px;
    }
    .is-required .el-form-item__label::before{
      display: none;
    }
    .login-btn{
      display: block;
      width: 160px;
      height: 40px;
      color: #fff;
      border-radius: 5px;
      font-size: 14px;
      line-height: 40px;
      padding: 0;
      margin: 0 auto;
    }
    .el-button.is-disabled{
      background: #ccc !important;
    }
    .getcode-btn{
      display: inline-block;
      width: 98px;
      font-size: 14px;
      color: #fff;
      height: 40px;
      text-align: center;
      margin-left: 10px;
    }
    .check-box{
      margin-bottom: 25px;
      text-align: center;
      .el-form-item__content{
        line-height: 16px;
        &>span{
          font-size: 12px;
          color: #2A67FF;
          font-weight: normal;
          margin-left: 10px;
        }
      }
    }
  }
  .protocol-container{
    .el-dialog__header{
      height: 60px;
      line-height: 60px;
      background:linear-gradient(135deg,rgba(81,123,252,.5) 0%,rgba(81,123,252,.5) 100%);
      .el-dialog__headerbtn{
        border-color:transparent #7C9CFD transparent transparent;
      }
      .el-dialog__title{
        font-size: 20px;
        font-weight: 500 !important;
      }
    }
    .el-dialog__body{
      height: 400px;
      padding: 30px 50px;
      font-size: 14px;
      color: #666;
      line-height: 22px;
      overflow: scroll;
      .title{
        font-weight: 500;
        font-size: 16px;
        color: #333;
        margin-bottom: 20px;
      }
      .p_content{
        max-height: 500px;
        overflow-y: auto;
      }
    }
  }
}
</style>
